<p-confirmDialog header="{{'confirm_change_level' | translate}}" icon="pi pi-exclamation-triangle"
acceptLabel="{{'yes' | translate}}" rejectLabel="{{'no' | translate}}"></p-confirmDialog>
<p-toast></p-toast>
@if (isLoading) {
  <loading class="d-block mt-5 pt-5" [isLoading]="isLoading" [text]="loadingText"></loading>
}
@if (!isLoading && !history) {
  <back-button [route]="['evaluators']" desktop="true" [gobackText]="[ 'Evaluators' | translate]"></back-button>
}
@if (!isLoading) {
  <div class="row pt-3" style="margin-inline: 0;">
    @if (noEvaluations) {
      <span class="section-title text-center w-100 mt-3">
        {{'no_historic_evaluations' | translate}}
      </span>
    } @else {
      @if (!history) {
        <div class="col-4 pt-3">
          <div class="user-info-container">
            <div class="section-subtitle mb-3">
              {{'login' | translate}}:&nbsp;{{user?.login}}
            </div>
            <div class="section-subtitle mb-3">
              <div class="row g-0 align-items-center">
                <div class="mb-1 interactive-input-width">
                  {{'current_level' | translate}}:
                  @if (!showChangeLevel) {
                    &nbsp;{{user?.currentLevel}}
                  }
                </div>
                @if (showChangeLevel) {
                  <div class="d-flex p-inputgroup interactive-input-width">
                    <input type="text" [(ngModel)]="newLevel" pInputText [placeholder]="'change_level_placeholder' | translate" [matAutocomplete]="auto" (input)="filterLevels($event.target.value)">
                    <button type="button" pButton pRipple icon="pi pi-check" (click)="changeLevel()" styleClass="p-button-success" class="button-save-level"></button>
                  </div>
                }
                @if (dataProvider?.loggedUser?.committee) {
                  <fa-icon class="clickable show-hide-element" (click)="showChangeLevel = !showChangeLevel" [matTooltip]="(showChangeLevel ? 'cancel_change' : 'exchange_level') | translate" [icon]="showChangeLevel? faTimes : faExchangeAlt"
                  matTooltipClass="tooltip-break-line-centered"></fa-icon>
                }
                <mat-autocomplete matInput #auto="matAutocomplete">
                  @for (level of filteredWords; track level) {
                    <mat-option [value]="level.label">
                      {{level.label}}
                    </mat-option>
                  }
                </mat-autocomplete>
              </div>
            </div>
            <div class="section-subtitle mb-3">
              <div class="mb-1 interactive-input-width">
                {{'evaluator' | translate}}:&nbsp;
                @if (!showChangeEvaluator) {
                  {{user?.evaluator}}
                }
              </div>
              @if (showChangeEvaluator) {
                <span class="interactive-input-width">
                  <p-dropdown
                    appendTo="body"
                    maxSelectedLabels="1"
                    (onChange)="updateEvaluator()"
                    [options]="partners"
                    [filter]="true" filterBy="label"
                    [(ngModel)]="cycleEvaluator"
                  [style]="{'display': 'flex', 'width': '100%'}"></p-dropdown>
                </span>
              }
              @if (dataProvider?.loggedUser?.committee || dataProvider?.loggedUser?.partner) {
                <fa-icon class="clickable show-hide-element" (click)="showChangeEvaluator = !showChangeEvaluator" [matTooltip]="(showChangeEvaluator ? 'cancel_change' : 'exchange_evaluator') | translate" [icon]="showChangeEvaluator? faTimes : faExchangeAlt"
                matTooltipClass="tooltip-break-line-centered"></fa-icon>
              }
            </div>
            <div class="section-subtitle">
              <div class="mb-1 interactive-input-width">
                {{'mentor' | translate}}:&nbsp;
                @if (!showChangeMentor) {
                  {{user?.mentor?.name}}
                }
              </div>
              @if (showChangeMentor) {
                <span class="interactive-input-width">
                  <p-dropdown
                    appendTo="body"
                    maxSelectedLabels="1"
                    (onChange)="updateMentor()"
                    [options]="mentors"
                    [filter]="true" filterBy="label"
                    [(ngModel)]="mentorId"
                  [style]="{'display': 'flex', 'width': '100%'}"></p-dropdown>
                </span>
              }
              @if (dataProvider?.loggedUser?.committee) {
                <fa-icon class="clickable show-hide-element" (click)="showChangeMentor = !showChangeMentor" [matTooltip]="(showChangeMentor ? 'cancel_change' : 'exchange_mentor') | translate" [icon]="showChangeMentor? faTimes : faExchangeAlt"
                matTooltipClass="tooltip-break-line-centered"></fa-icon>
              }
            </div>
            <div class="section-subtitle mt-3 row g-0">
              <div class="interactive-input-width">
                {{'evaluation_counts_for_carrier' | translate}}:
              </div>
              <div class="show-hide-element">
                <p-inputSwitch [(ngModel)]="countForCarrierCheck" (onChange)="updateCountForCarrier()" [disabled]="!dataProvider.loggedUser.committee"></p-inputSwitch>
              </div>
            </div>
            @if (dataProvider?.loggedUser?.committee && lastCycle?.selfGrades != undefined && lastCycle?.selfGrades.applied == false) {
              <div class="section-subtitle mt-3 row g-0">
                <div class="interactive-input-width">
                  {{'user_did_not_send_self_evaluation' | translate}}
                </div>
                <div class="show-hide-element">
                  <button class="btn send-btn" (click)="setSelfEvaluationAppliedAsTrue()" [style]="'background-color: '+ dataProvider?.cssBasics?.primaryButtonBackgroundColor +'; color: '+dataProvider?.cssBasics?.primaryButtonColor">
                    {{ 'yes' | translate}}
                  </button>
                </div>
              </div>
            }
          </div>
        </div>
        <div class="col-4 large-text fw-bold text-center">
          {{user?.name}}
          <div class="mt-2 mb-2">
            <div class="mt-3 mb-3">
              <p-dropdown
                [options]="allVisagians"
                [(ngModel)]="selectedVisagian"
                (onChange)="changeEvaluate()"
                optionLabel="name"
                [filter]="true"
                appendTo="body"
                [showClear]="true"
                placeholder="{{ 'change_visagian' | translate}}">
              </p-dropdown>
            </div>
            <fa-icon class="clickable" (click)="hideOrShowData()" [matTooltip]="(showData ? 'activate_presenter_mode' : 'deactivate_presenter_mode') | translate" [icon]="showData ? faEye : faEyeSlash"></fa-icon>
          </div>
        </div>
        <div class="col-4 pt-3">
          @if (!cycleOpenForSelfEvaluation) {
            <button [disabled]="noEvaluatorGrade || (!isEqualization && dataProvider.managerEvaluationApplied) || (isEqualization && dataProvider.equalizationApplied) || (isEqualization && !dataProvider.loggedUser.committee)" (click)="apply()" class="btn send-btn" [style]="'background-color: '+ dataProvider?.cssBasics?.primaryButtonBackgroundColor +'; color: '+dataProvider?.cssBasics?.primaryButtonColor">
              {{((!isEqualization && dataProvider.managerEvaluationApplied) ? 'evaluation_sent' : (isEqualization && dataProvider.equalizationApplied) ? 'equalization_sent' : (isEqualization ? 'send_equalization' : 'send_evaluation')) | translate}}
            </button>
            @if (noEvaluatorGrade) {
              <div class="pending-filds-text">
                {{'pending_fields' | translate}}
              </div>
            }
            @if (isEqualization && !dataProvider.loggedUser.committee) {
              <div class="only-committee-text">
                {{'only_committe_send' | translate}}
              </div>
            }
          }
        </div>
      }
      <div class="col-12">
        @if (data) {
          <div class="ms-4 me-4 mt-3">
            <evaluate-cycles [cycles]="data" [history]="history" [showData]="showData" [checkPendingGrades]="checkPendingGrades.bind(this)"></evaluate-cycles>
          </div>
        }
      </div>
    }
  </div>
}
