
<div class="mt-4">
  @if (comments.length == 0) {
    <div class="mt-3 mb-3 text-center fw-bold">
      {{'no_comments' | translate}}
    </div>
  } @else {
    @for (comment of comments; track comment) {
      <div [ngClass]="{'align-right-balloon': comment.byMe}" >
        <div class="comment-balloon mb-3" [ngClass]="comment.byMe ? 'right-balloon' : 'left-balloon' ">
          <div class="comment-header">
            <span class="me-4" [ngStyle]="{'color': comment.userColor}">{{comment.userName}}</span>
          </div>
          <div [innerHTML]="comment.comments"></div>
          <div class="footer-text">
            <fa-icon [icon]="faHistory"></fa-icon>
            <span class="ms-2">{{comment.lastModifiedDate}}</span>
          </div>
        </div>
      </div>
    }
  }
</div>
<div class="text-end me-5">
  <button (click)="saveComment()" [disabled]="isSaving || isLoading || !currentCommentText || currentCommentText=='\<p>\</p>'" class="btn mb-3" [style]="'background-color: '+ dataProvider?.cssBasics?.primaryButtonBackgroundColor +'; color: '+dataProvider?.cssBasics?.primaryButtonColor">
    @if (!isSaving) {
      {{ 'send_comment' | translate}}
    }
    <loading [fontSize]="1.4" color="#fff" [isLoading]="isSaving"></loading>
  </button>

  <button (click)="updateComments()" [disabled]="isLoading" class="btn mb-3 ms-3">
    @if (!isLoading) {
      <fa-icon
        matTooltip="{{ 'update_comments' | translate }}"
        class="warning-icon update-icon" [icon]="faRedo">
      </fa-icon>
    }
    <loading [fontSize]="1.4" color="#000" [isLoading]="isLoading"></loading>
  </button>
</div>

<p-editor [disabled]="isLoading || isSaving" [(ngModel)]="currentCommentText" [style]="{'min-height':'65px'}"></p-editor>
